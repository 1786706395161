<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 2C8.44772 2 8 2.44772 8 3V18C8 18.5523 8.44772 19 9 19H11C11.5523 19 12 18.5523 12 18V3C12 2.44772 11.5523 2 11 2H9ZM3 6C2.44772 6 2 6.44772 2 7V18C2 18.5523 2.44772 19 3 19H5C5.55228 19 6 18.5523 6 18V7C6 6.44772 5.55228 6 5 6H3ZM14.1479 10.2514C13.9007 9.75754 14.1007 9.15678 14.5945 8.90957L16.383 8.01435C16.8769 7.76714 17.4776 7.9671 17.7248 8.46097L21.8681 16.7383C22.1153 17.2321 21.9153 17.8329 21.4215 18.0801L19.633 18.9753C19.1391 19.2225 18.5384 19.0226 18.2912 18.5287L14.1479 10.2514ZM22 21C22 20.4477 21.5523 20 21 20H3C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
